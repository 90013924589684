html {
  position: relative;
  min-height: 100%;
}

body {
  /* Padding and margin added to support the footer */
  padding-top: 80px;
  margin-bottom: 80px;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer */
  line-height: 60px; /* Vertically center text */
  background-color: #f5f5f5;
}

.page-heading {
  margin-top: 0rem;
  margin-bottom: 1rem;
  text-align: center !important;
}

/* Used to toggle whether elements appear in DOM or not. */
.hidden {
  display: none;
}

.item-content {
  /* Wrap long words, should still display in div. */
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
  margin: 0;
  padding: 0;
}

.list-content {
  word-wrap: break-word;
  width: 100%;
}

.list-image {
  border-radius: 10px !important;
  border: 2px solid #dee2e6 !important;
}

#username {
  text-transform: lowercase;
}

.bi {
  display: inline-block;
  vertical-align: -.125em;
  height: 1em;
  width: 1em;
}

/* Navbar autohiding */
.autohide {
    position: fixed !important;
    top: 0;
    width:100%;
}

.scrolled-down {
  transform: translateY(-100%);
  transition: all 0.3s ease-in-out;
}

.scrolled-up {
  transform: translateY(0);
  transition: all 0.3s ease-in-out;
}
