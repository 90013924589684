html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: 80px;
  padding-top: 80px;
}

.footer {
  width: 100%;
  height: 60px;
  background-color: #f5f5f5;
  line-height: 60px;
  position: absolute;
  bottom: 0;
}

.page-heading {
  margin-top: 0;
  margin-bottom: 1rem;
  text-align: center !important;
}

.hidden {
  display: none;
}

.item-content {
  word-wrap: break-word;
  width: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.list-content {
  word-wrap: break-word;
  width: 100%;
}

.list-image {
  border: 2px solid #dee2e6 !important;
  border-radius: 10px !important;
}

#username {
  text-transform: lowercase;
}

.bi {
  vertical-align: -.125em;
  height: 1em;
  width: 1em;
  display: inline-block;
}

.autohide {
  width: 100%;
  top: 0;
  position: fixed !important;
}

.scrolled-down {
  transition: all .3s ease-in-out;
  transform: translateY(-100%);
}

.scrolled-up {
  transition: all .3s ease-in-out;
  transform: translateY(0);
}

/*# sourceMappingURL=main.css.map */
